var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-custom" },
    [
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: { target: _vm.category, position: "position1" }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: { target: _vm.category, position: "position2" }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-3",
        attrs: { target: _vm.category, position: "position3" }
      }),
      _c(
        "v-container",
        [
          _c("ebsn-meta", {
            attrs: {
              target: _vm.category,
              path: "category_info.FOOTER_DESCRIPTION",
              tag: "div"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }